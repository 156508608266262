// 选择课程的弹框
<template>
    <div class="choose_course">
        <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="real_content">
                    <!-- 加载框 -->
                    <Loading v-if="showLoading"></Loading>
                    <!-- 内容 -->
                    <div class="choose_course_dialog_content" v-else>
                        <!-- 列表布局 -->
                        <div class="course_list">
                            <div class="item_course" v-for="(item, index) in courseData" :key="index" @click="doItemClick(item)">
                                <!-- 图片 -->
                                <div class="dv_show_img">
                                    <img :src="item.subjectImg" :class="[
                      'img_course',
                      { activeBorder: item.subjectState == 1 },
                    ]" />
                                </div>

                                <!-- 姓名 -->
                                <div :class="[
                    'name_course',
                    { activeColor: item.subjectState == 1 },
                  ]">
                                    {{ item.subjectName }}
                                </div>

                                <!-- 时间 -->
                                <div :class="['time_course']">
                                    有效期：{{ item.startTime }}-{{ item.expireTime }}
                                </div>
                            </div>
                        </div>
                        <!-- 底部的取消和确定按钮 -->
                        <div class="bom_action">
                            <!-- 取消 -->
                            <div class="cancel" @click.stop="close">
                                <span>取</span><span style="margin-left: 16px">消</span>
                            </div>
                            <!-- 确定 -->
                            <el-button :loading="showCommitLoading" :class="['commit']" @click.stop="doCommitAction">
                                <span>确</span><span style="margin-left: 16px">定</span>
                            </el-button>
                        </div>

                        <!-- 确定取消框 -->
                        <!-- <div class="inner_dialog"></div> -->
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
export default {
    data() {
        return {
            //宽度
            width: "640px",
            //是否显示确定按钮加载框的标识
            showCommitLoading: false,
            //显示加载框
            showLoading: true,
            courseData: [],
        };
    },
    created() {
        //获取数据
        this.httpData();
    },
    methods: {
        //获取列表数据
        async httpData() {
            let param = this.getHttpParams();
            param.type = 8;
            param.cid = this.cid;
            let res = await this.$http.fetchPost(
                this.$api.TEACHER_CLASS_MANAGER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //表示请求成功
                this.courseData = res.data.subjectList;
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },

        //关闭弹框
        close() {
            //触发父组件的关闭方法
            this.$emit("close");
        },
        //确定的操作
        doCommitAction() {
            this.showCommitLoading = true;
            this.httpCourseState();
        },

        //请求同步课程状态的接口
        async httpCourseState() {
            let param = this.getHttpParams();
            param.type = 9;
            param.subIdList = this.getSubIdList();
            param.cid = this.cid;
            let res = await this.$http.fetchPost(
                this.$api.TEACHER_CLASS_MANAGER,
                param
            );
            this.showLoading = false;
            if (res.data.state == 200) {
                //表示请求成功,通知班级界面进行数据刷新
                this.showCommitLoading = false;
                this.$emit("updatePage");
                this.close();
            } else {
                //直接将服务器返回的错误内容弹出提示
                this.showErrorMsg(this.getMesage(res.data.state));
            }
        },

        //获取传递选中课程的数据
        getSubIdList() {
            let selectedCourse = this.courseData.filter(
                (item) => item.subjectState == 1
            );
            let subIdsArr = selectedCourse.map((obj) => {
                return obj.subjectId;
            });

            return subIdsArr.join(",");
        },

        //条目的点击事件
        doItemClick(item) {
            //改变选中状态
            if (item.subjectState == 1) {
                //取消
                if (item.studyRecord != 0) {
                    //有学习记录
                    this.$confirm(
                        "该班级当前课程已有学习记录，确认更换课程权限吗？（注意：更换课程权限后将会影响学生访问原课程）",
                        "提示",
                        {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                        }
                    ).then(() => {
                        //点击确定的操作
                        item.subjectState = 0;
                    });
                } else {
                    item.subjectState = 0;
                }
            } else if (item.subjectState == 0) {
                //选中
                item.subjectState = 1;
            }
        },
    },
    computed: {},
    components: {
        CommonDialog,
    },
    props: ["showDialog", "title", "cid"],
};
</script>
<style lang="scss" scoped>
.choose_course {
    ::v-deep .el-dialog {
        top: 50%;
        // background: #eff2f7;
    }

    .real_content {
        min-height: 550px;
        .choose_course_dialog_content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;
            .course_list {
                display: flex;
                flex-wrap: wrap;
                min-height: 500px;

                .item_course {
                    width: 33%;
                    height: 210px;
                    background: white;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    cursor: pointer;
                    margin-bottom: 10px;
                    .dv_show_img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 68%;
                        height: 110px;
                        .img_course {
                            width: 70%;
                            height: 110px;
                            object-fit: contain;
                            border-radius: 4px;
                        }
                    }
                    .activeBorder {
                        border: 2px solid #80c6ff;
                    }

                    .name_course {
                        margin-top: 12px;
                        color: black;
                        font-size: 12px;
                        font-weight: bold;
                    }
                    .activeColor {
                        color: #80c6ff;
                    }

                    .time_course {
                        margin-top: 10px;
                        font-size: 10px;
                        color: $text_gray;
                    }
                }
            }

            //底部的操作按钮
            .bom_action {
                margin-top: 20px;
                height: 48px;
                display: flex;
                justify-content: center;

                .cancel,
                .commit {
                    width: 120px;
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .cancel {
                    background: #dcdcdc;
                    margin-right: 30px;
                    color: black;
                }

                .commit {
                    background: $common_bg;
                    color: white;
                }
            }

            //操作框
            .inner_dialog {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba($color: #000000, $alpha: 0.4);
            }
        }
    }
}
</style>