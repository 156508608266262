//老师端的班级管理
<template>
  <div class="teacher_manager_class">
    <div class="content" v-if="!showLoading">
      <!-- 顶部的班级管理功能 -->
      <div class="top_info">
        <!-- 左边的班级管理和班级信息 -->
        <div class="class_info">
          <div class="text_class">班级管理</div>
        </div>

        <!-- 右边的创建新班级 -->
        <div class="top_right" v-if="false">
          <!-- 剩余班级个数 -->
          <div>全校剩余有效班级数：{{ schoolRemainNum }}个</div>
          <!-- v-if="false" -->
          <div class="create_class" @click.stop="createClassDialog">
            创建新班级
          </div>
        </div>
      </div>

      <!-- 显示内容的部分 -->
      <div class="show_content">
        <!-- 班级列表部分 -->
        <div class="show_class_list" v-if="isShowContent">
          <!-- 列表 -->
          <div class="top_list">
            <div
              class="item_class"
              v-for="(item, index) in currData"
              :key="index"
            >
              <div class="item_class_content">
                <!-- 顶部添加的开放的课程以及添加课程按钮 -->
                <div class="course_info">
                  <span>开放的课程：</span>
                  <div class="course_content">
                    <!-- 没有课程显示的空布局 -->
                    <span v-if="!item.showCourse">暂无课程</span>
                    <!-- 课程的详细信息  -->
                    <div class="course_details" v-else>
                      <!-- {{ item.courseContnt }} -->
                      <div
                        class="cousr_item"
                        v-for="course in item.subjectList"
                        :key="course.subjectId"
                      >
                        {{ course.subjectName }}
                      </div>
                    </div>
                  </div>
                  <!--   v-if="item.hasOverCourse" -->
                  <div
                    class="add_course"
                    v-if="false"
                    @click.stop="openChooseCourseDialog(item)"
                  >
                    添加课程
                  </div>
                </div>

                <!-- 顶部,班级名,人数,复制码等 -->
                <div class="top">
                  <!-- 班级名 -->
                  <span class="class_name"
                    >班级名：{{ item.classname }}&nbsp;&nbsp;({{
                      item.totalNum
                    }}&nbsp;人)</span
                  >

                  <!-- 编辑按钮 -->
                  <span
                    class="iconfont cloud-bianji"
                    @click.stop="showEdtiNameDialog(item)"
                  ></span>

                  <!-- 班级id -->
                  <span class="class_id">ID：{{ item.class_id }}</span>

                  <!-- 邀请码的值 -->
                  <span class="invite_code"
                    >邀请码：{{ item.invitationCode }}</span
                  >

                  <!-- 复制邀请码 -->
                  <span class="copy_code" @click.stop="copyInvitCode(item)"
                    >复制</span
                  >

                  <!-- 占位 -->
                  <span class="place_holder"></span>

                  <!-- 消息 -->
                  <span
                    class="iconfont cloud-xiaoxizhongxin"
                    @click.stop="showClassMesage(item)"
                  ></span>

                  <!-- 消息数量 -->
                  <span class="message_num">({{ item.infoNum }})</span>
                </div>
                <!-- 中间显示班级学生的部分 -->
                <div class="mid">
                  <!-- 显示学生的分组数据 -->
                  <div class="show_student_list" v-if="item.totalPage != 0">
                    <!-- 左边的分页按钮 -->
                    <button
                      type="button"
                      :class="['left_btn', { btn_disable: item.currPage == 1 }]"
                      v-if="item.totalPage > 1"
                      :disabled="item.currPage == 1"
                      @click.stop="showPreStuent(item)"
                    >
                      <i class="el-icon el-icon-arrow-left"></i>
                    </button>
                    <!-- 学生列表展示部分 -->
                    <div class="show_student_content">
                      <div
                        class="item_stuent_info"
                        v-for="(student, pos) in item.currStudentArr"
                        :key="pos"
                      >
                        <!-- 头像 -->
                        <div class="head">
                          <img
                            class="stu_img animated fadeIn"
                            :src="student.user_img"
                          />

                          <!-- 操作选中的布局 -->
                          <img
                            @click.stop="doChooseAction(item, student)"
                            v-show="item.isShowAction"
                            :class="[
                              student.isSelected ? 'stu_select' : 'stu_unselect'
                            ]"
                          />
                        </div>
                        <!-- 姓名 -->
                        <div class="stu_name" :title="student.name">
                          {{ student.name }}
                        </div>
                      </div>
                    </div>
                    <!-- 右边的分页按钮 -->
                    <button
                      type="button"
                      @click.stop="showNextStuent(item)"
                      :class="[
                        'right_btn',
                        { btn_disable: item.currPage == item.totalPage }
                      ]"
                      v-if="item.totalPage > 1"
                      :disabled="item.currPage == item.totalPage"
                    >
                      <i class="el-icon el-icon-arrow-right"></i>
                    </button>
                  </div>
                  <!-- 没有学生的空视图 -->
                  <div class="empty_styent" v-else>该班级暂无学生~</div>
                </div>
                <!-- 底部显示操作部分 班级有学生时才展示 -->
                <div class="bom" v-if="item.totalPage != 0">
                  <!-- 显示班级人数 -->
                  <span>{{ item.currPage }}/{{ item.totalPage }}</span>
                  <!-- 管理按钮 -->
                  <span class="manager" @click="doClickManagerAction(item)"
                    >管理</span
                  >
                  <!-- 全选,删除按钮 -->
                  <div
                    class="all_del animated fadeIn"
                    v-show="item.isShowAction"
                  >
                    <span class="all" @click.stop="doChooseAll(item)">{{
                      item.showMsg
                    }}</span>

                    <span class="del" @click.stop="doDelAction(item)"
                      >删除</span
                    >
                  </div>

                  <!-- 占位 -->
                  <div class="placholder_view"></div>
                </div>
                <!-- 删除班级的按钮 -->
                <span
                  class="iconfont cloud-lajitong"
                  @click.stop="doDelClass(item)"
                ></span>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="bom_page">
            <el-pagination
              @current-change="handleCurrentChange"
              background
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="totalSize"
              :current-page.sync="currPage"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 空视图部分 -->
        <el-empty description="暂无班级，快创建新班级吧~" v-else></el-empty>
      </div>
    </div>

    <!-- 加载框 -->
    <Loading v-if="showLoading"></Loading>

    <!-- 删除学生的弹框 -->
    <DelStuDialog
      ref="delstudents"
      v-if="showDelStuDialog"
      @close="closeDelStuDialog"
      :showDialog="showDelStuDialog"
      :title="delStuTitle"
      :stuNames="stuNames"
      @delStudents="delStudents"
    />

    <!-- 创建新班级的弹框 -->
    <CreateNewClass
      ref="createclass"
      v-if="showCreateClassDialog"
      @close="closeCreateClassDialog"
      :showDialog="showCreateClassDialog"
      :title="createClassTitle"
      @createNewClass="createNewClass"
    />

    <!-- 班级消息 -->
    <ClassMessage
      v-if="showClassMsg"
      @refreshData="refreshPageData"
      @close="closeClassMsgDialog"
      :showDialog="showClassMsg"
      :title="classMsgTitle"
      :cid="currClassObj.class_id"
    />

    <!-- 修改班级名称 -->
    <UpdateClassNameDialog
      ref="editclassname"
      @updateClassName="updateClassName"
      v-if="showEditNameDialog"
      @close="closeEditNameDialog"
      :showDialog="showEditNameDialog"
      :title="editNameTitle"
    />

    <!-- 删除班级的弹框 -->
    <DelClassDialog
      ref="delclass"
      v-if="showDelClassDialog"
      @close="closeDelClassDialog"
      :showDialog="showDelClassDialog"
      :title="delClassTitle"
      @delClass="delClass"
    />

    <!-- 选择课程的弹框 -->
    <ChooseCourse
      @updatePage="refreshPageData"
      v-if="showChooseCourseDialog"
      @close="closeChooseCourseDialog"
      :showDialog="showChooseCourseDialog"
      :title="chooseCourseTitle"
      :cid="currClassObj.class_id"
    />
  </div>
</template>
<script>
//删除班级学生
import DelStuDialog from "@/views/dialog/DelStuDialog";
//创建新班级
import CreateNewClass from "@/views/dialog/CreateNewClass";
//班级消息
import ClassMessage from "@/views/dialog/ClassMessage";
//班级名称
import UpdateClassNameDialog from "@/views/dialog/UpdateClassNameDialog";
//删除班级
import DelClassDialog from "@/views/dialog/DelClassDialog";
//选择课程的弹框
import ChooseCourse from "@/views/dialog/ChooseCourse";
export default {
  data() {
    return {
      //加载框的操作
      showLoading: true,

      //当前操作的班级对象
      currClassObj: null,

      //有班级数据时控制的显示状态值
      isShowContent: true,

      //每个班级每页显示的学生数,默认是10个
      pageNum: 10,

      //删除学生的弹框控制值
      showDelStuDialog: false,

      //创建新班级的弹框控制值
      showCreateClassDialog: false,

      //班级消息的弹框控制值
      showClassMsg: false,

      //修改班级名称的弹框控制值
      showEditNameDialog: false,

      //删除班级的弹框控制值
      showDelClassDialog: false,

      //选择课程的弹框控制值
      showChooseCourseDialog: false,

      //删除学生的弹框表
      delStuTitle: "删除学生",

      //创建新班级
      createClassTitle: "创建新班级",

      //班级消息
      classMsgTitle: "班级消息",

      //修改班级名称
      editNameTitle: "修改班级名称",

      //删除班级
      delClassTitle: "删除班级",

      //选择课程
      chooseCourseTitle: "请选择课程",

      //学校的状态 -1表示到期,预留处理
      school_state: 0,

      //学校剩余班级数
      schoolRemainNum: -1,

      //学校开通的科目数
      subjectNum: 0,

      //所有的班级数据
      classData: [],

      //当前页面显示的班级数据
      currData: [],

      //数据总数
      totalSize: -1,

      //每页显示的班级数量,默认显示2个
      pageSize: 2,

      //当前的页码数据,默认第一页
      currPage: 1,

      //删除的学生姓名
      stuNames: ""
    };
  },
  created() {},
  mounted() {
    //这里先需要请求服务器获取班级的数据,获取到班级数据后在进行数组组装
    this.httpData();
  },
  methods: {
    //获取老师端请求接口获取公共参数的方法
    getParams(type) {
      let param = this.getHttpParams();
      param.type = type;
      return param;
    },

    //请求班级数据
    async httpData() {
      let param = this.getParams(1);
      let res = await this.$http.fetchPost(
        this.$api.TEACHER_CLASS_MANAGER,
        param
      );

      if (res.data.state == 200) {
        //关闭Loading
        this.showLoading = false;

        //获取学校的状态
        this.school_state = res.data.school_state;

        //获取学校剩余的班级数据
        this.schoolRemainNum = res.data.schoolRemainNum;

        //获取学校开通的科目数
        this.subjectNum = res.data.subjectNum;

        let classInfo = res.data.classinfo;

        if (!this.isEmpty(classInfo) && classInfo.length > 0) {
          //记录班级总数
          this.totalSize = classInfo.length;

          //重置当前的页面,默认第一页选中
          // this.currPage = 1;

          //有班级
          this.isShowContent = true;

          //获取请求到的学校数据
          this.classData = classInfo;

          //在对学校数据进行组装
          this.assemblyData();
        } else {
          //没有班级
          this.isShowContent = false;
        }
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //对每个班级的数据做分组处理
    assemblyData() {
      this.classData.forEach((item, index) => {
        this.sysClassData(item, index);
      });

      //在对班级数据进行分页处理
      this.sysCurrClassData();
    },

    //对当前显示的班级数据进行处理,:当前页面,初次进来取1
    sysCurrClassData() {
      //先清空一下当前数据
      this.currData.splice(0, this.currData.length);
      let start = (this.currPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      if (end > this.classData.length) {
        end = this.classData.length;
      }
      for (let i = start; i < end; i++) {
        this.currData.push(this.classData[i]);
      }
    },

    //同步每个班级的数据
    sysClassData(item, index) {
      //配置是否显示全选,删除的控制值
      this.$set(item, "isShowAction", false);

      //配置全选和反选的标识
      this.$set(item, "showMsg", "全选");

      //取出班级对应的课程信息
      //配置一个课程是否显示的的标识,最多9个课程,
      //这里需要先判断是否有课程信息,如果没有,显示添加课程,hasOverCourse为true,如果有,超过9个课程hasOverCourse为true,否则为false
      let sub = item.subjectList;

      if (this.isEmpty(sub) || sub.length == 0) {
        //表示没有课程信息
        //配置一个是否显示课程信息的标识,如果有课程信息,showCourse为true,没有为false,这个是控制课程空视图和课程内容的
        this.$set(item, "showCourse", false);
        //显示添加课程
        this.$set(item, "hasOverCourse", true);
        //配置一个空的课程内容
        this.$set(item, "courseContnt", "");
      } else {
        //有课程信息
        //配置一个是否显示课程信息的标识,如果有课程信息,showCourse为true,没有为false,这个是控制课程空视图和课程内容的
        this.$set(item, "showCourse", true);

        //配置一下课程信息的内容
        let subjectNameArr = sub.map(item => {
          return item.subjectName;
        });

        //配置一个课程内容
        this.$set(item, "courseContnt", subjectNameArr.join(","));

        //判断一下课程信息是否超过9个
        // if (sub.length >= 9) {
        //   //不显示添加课程
        //   this.$set(item, "hasOverCourse", true);
        // } else {
        //显示添加课程
        this.$set(item, "hasOverCourse", true);
        // }
      }

      //配置当前页面
      if (!this.isEmpty(item.students) && item.students.length > 0) {
        //班级有学生
        //取出所有的学生
        let allStudent = item.students;

        //给每个学生赋值响应式的属性
        allStudent.forEach(student => {
          //配置是否选中的属性
          this.$set(student, "isSelected", false);
        });

        //有学生
        this.$set(item, "currPage", 1);

        //配置分组的数据
        let studentGroup = [];
        for (
          let i = 0, len = item.students.length;
          i < len;
          i += this.pageNum
        ) {
          studentGroup.push(item.students.slice(i, i + this.pageNum));
        }

        this.$set(item, "studentGroup", studentGroup);

        //配置总页面数
        this.$set(item, "totalPage", item.studentGroup.length);

        //配置班级的人数
        this.$set(item, "totalNum", item.students.length);

        //配置当前需要显示的学生数据,默认是第一页的数据
        let currStudentArr = [];
        item.studentGroup[0].forEach(item => {
          currStudentArr.push(item);
        });

        this.$set(item, "currStudentArr", currStudentArr);
      } else {
        //没有学生
        this.$set(item, "currPage", 0);
        this.$set(item, "totalPage", 0);
        //配置班级的人数
        this.$set(item, "totalNum", 0);
      }
    },

    //每个学生的选中操作
    doChooseAction(item, student) {
      student.isSelected = !student.isSelected;
      //判断是否全部选中
      if (this.hasChooseAll(item)) {
        if (item.showMsg != "反选") {
          item.showMsg = "反选";
        }
      }
      //判断是否有未选中的
      if (this.hasUnChoose(item)) {
        if (item.showMsg != "全选") {
          item.showMsg = "全选";
        }
      }
    },

    //切换上一页的学生
    showPreStuent(item) {
      //获取当前页面
      item.currPage = item.currPage - 1;

      if (item.currPage > 0) {
        this.sysCurrPageStudent(item);
      }
    },

    //切换下一页的学生
    showNextStuent(item) {
      //获取当前页面
      item.currPage = item.currPage + 1;

      if (item.currPage <= item.totalPage) {
        this.sysCurrPageStudent(item);
      }
    },

    //同步显示当前页面学生的状态
    sysCurrPageStudent(item) {
      //获取组的数据
      let groupSutent = item.studentGroup;

      //获取当前显示的组
      let currGroup = item.currStudentArr;

      //清空当前显示的组的数据
      currGroup.splice(0, currGroup.length);

      let showStuArr = [];

      //取出切换后页面的学生数据
      showStuArr = groupSutent[item.currPage - 1];

      //将学生数据放入当前页面显示的数组中
      showStuArr.forEach(item => {
        currGroup.push(item);
      });
    },

    //全选的操作
    doChooseAll(item) {
      item.showMsg === "全选"
        ? (item.showMsg = "反选")
        : (item.showMsg = "全选");
      let student = item.students;
      student.forEach(obj => {
        if (item.showMsg === "反选") {
          if (!obj.isSelected) {
            obj.isSelected = true;
          }
        } else {
          if (obj.isSelected) {
            obj.isSelected = false;
          }
        }
      });
    },

    //删除的操作
    doDelAction(item) {
      if (this.hasStudentChoosed(item)) {
        //记录当前操作的的班级对象
        this.currClassObj = item;

        //获取所有选中学生的姓名
        this.stuNames = this.getDelStuName();

        //有学生被选中,显示删除学生的弹框
        this.showDelStuDialog = true;
      } else {
        //没有学生被选中
        this.showWarnMsg("请先选择需要删除的学生~~");
      }
    },

    //获取删除学生的所有姓名
    getDelStuName() {
      //获取当前班级的学生
      let student = this.currClassObj.students;

      //获取所有选中的学生
      let allSelectStu = student.filter(item => item.isSelected == true);

      //获取所有选中学生的id
      let namesArr = allSelectStu.map(item => {
        return item.name;
      });

      //拼接name
      return namesArr.join(",");
    },

    //判断是否有学生被选中
    hasStudentChoosed(item) {
      //获取所有的学生
      let student = item.students;
      let res = false;
      for (let i = 0; i < student.length; i++) {
        if (student[i].isSelected) {
          res = true;
          break;
        }
      }
      return res;
    },

    //复制邀请码
    copyInvitCode(item) {
      var temp = document.createElement("textarea");
      temp.value = item.invitationCode;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      temp.style.display = "none";
      this.showSuccessMsg("复制成功");
    },

    //点击管理的操作
    doClickManagerAction(item) {
      item.isShowAction = !item.isShowAction;
      if (!item.isShowAction) {
        //关闭状态的话将所有选中的状态还原
        let student = item.students;
        student.forEach(item => {
          if (item.isSelected) {
            item.isSelected = false;
          }
        });

        //将状态值设置为全选状态
        item.showMsg = "全选";
      }
    },

    //判断学生是否全部选中
    hasChooseAll(item) {
      let res = true;
      let student = item.students;
      for (let i = 0; i < student.length; i++) {
        if (!student[i].isSelected) {
          res = false;
          break;
        }
      }
      return res;
    },

    //判断是否有选中,默认返回没有选中
    hasUnChoose(item) {
      let res = false;
      let student = item.students;
      for (let i = 0; i < student.length; i++) {
        if (!student[i].isSelected) {
          res = true;
          break;
        }
      }
      return res;
    },

    //关闭删除学生的弹框
    closeDelStuDialog() {
      this.showDelStuDialog = false;
    },

    //移除学生的操作
    async delStudents() {
      //将当前班级的选中的学生全部删除 currClassObj
      //获取当前班级的学生
      let student = this.currClassObj.students;

      //获取所有选中的学生
      let allSelectStu = student.filter(item => item.isSelected == true);

      //获取所有选中学生的id
      let idsArr = allSelectStu.map(item => {
        return item.uid;
      });

      //拼接id
      let stuList = idsArr.join("-");

      //请求删除接口
      let param = this.getParams(7);

      //创建班级的名称
      param.stuList = stuList;

      //班级id
      param.cid = this.currClassObj.class_id;

      let res = await this.$http.fetchPost(
        this.$api.TEACHER_CLASS_MANAGER,
        param
      );
      if (res.data.state == 200) {
        //重新刷新页面数据
        this.httpData();
        //改变子组件的Loading
        this.$refs.delstudents.showCommitLoading = false;
        //将删除学生的对话框关闭
        this.closeDelStuDialog();
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
        //改变子组件的Loading
        this.$refs.delstudents.showCommitLoading = false;
      }
    },

    //创建新班级的操作
    createClassDialog() {
      //判断当前校区可用数量是否大于0,大于则证明可以创建新班级
      if (this.schoolRemainNum == 0) {
        this.showWarnMsg("校区班级数量已达上限");
      } else {
        //打开创建班级的弹框
        this.showCreateClassDialog = true;
      }
    },

    //关闭创建新班级的弹框
    closeCreateClassDialog() {
      this.showCreateClassDialog = false;
    },

    //创建新班级
    createNewClass(className) {
      //创建新班级的操作
      this.doHttpCreateClass(className);
    },

    //创建新班级的请求服务器操作
    async doHttpCreateClass(className) {
      let param = this.getParams(6);
      //创建班级的名称
      param.name = className;

      let res = await this.$http.fetchPost(
        this.$api.TEACHER_CLASS_MANAGER,
        param
      );

      if (res.data.state == 200) {
        //重新刷新页面数据
        this.httpData();

        //改变子组件的Loading
        this.$refs.createclass.showCommitLoading = false;

        //创建班级成功后,关闭创建班级的弹框
        this.closeCreateClassDialog();

        //发一个消息通过未读消息数量+1
        this.$bus.emit("refrshUnReadMsg");
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));

        //改变子组件的Loading
        this.$refs.createclass.showCommitLoading = false;
      }
    },

    //关闭班级消息弹框
    closeClassMsgDialog() {
      this.showClassMsg = false;
    },

    //显示班级消息
    showClassMesage(item) {
      // if (!this.isEmpty(item.infoNum) && item.infoNum != 0) {
      if (!this.isEmpty(item.allInfoNum) && item.allInfoNum != 0) {
        //记录一下当前的班级对象
        this.currClassObj = item;
        //将当前未读消息重置为0
        item.infoNum = 0;
        this.showClassMsg = true;
      } else {
        this.showWarnMsg("暂无消息~");
      }
    },

    //显示编辑班级名称的弹框
    showEdtiNameDialog(item) {
      //记录一下当前的班级对象
      this.currClassObj = item;
      this.showEditNameDialog = true;
    },

    //修改班级名称
    async updateClassName(newName) {
      let param = this.getParams(2);
      //班级Id
      param.cid = this.currClassObj.class_id;
      //修改的班级名称
      param.cname = newName;
      let res = await this.$http.fetchPost(
        this.$api.TEACHER_CLASS_MANAGER,
        param
      );
      if (res.data.state == 200) {
        //修改班级名称成功
        // this.currClassObj.classname = newName;
        //重新刷新页面数据
        this.httpData();
        //改变子组件的Loading
        this.$refs.editclassname.showCommitLoading = false;
        //关闭弹框
        this.showEditNameDialog = false;
      } else {
        //改变子组件的Loading
        this.$refs.editclassname.showCommitLoading = false;
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //关闭编辑班级名称的弹框
    closeEditNameDialog() {
      this.showEditNameDialog = false;
    },

    //删除班级的操作
    doDelClass(item) {
      //获取当前班级的对象里面的学生
      //记录一下当前的班级对象
      this.currClassObj = item;
      let student = this.currClassObj.students;
      if (this.isEmpty(student) || student.length == 0) {
        this.showDelClassDialog = true;
      } else {
        this.showWarnMsg("班级中还存在学生,不能进行删除操作");
      }
    },

    //关闭删除班级的弹框
    closeDelClassDialog() {
      this.showDelClassDialog = false;
    },

    //删除班级请求服务器的操作
    async delClass() {
      //请求删除接口
      let param = this.getParams(22);
      param.groupId = this.currClassObj.class_id;

      let res = await this.$http.fetchPost(
        this.$api.TEACHER_CLASS_MANAGER,
        param
      );
      if (res.data.state == 200) {
        //删除班级后定位到第一页的数据
        this.currPage = 1;
        //重新刷新页面数据
        this.httpData();
        //改变子组件的Loading
        this.$refs.delclass.showCommitLoading = false;
        //将删除学生的对话框关闭
        this.showDelClassDialog = false;
        //发一个消息通过未读消息数量+1
        this.$bus.emit("refrshUnReadMsg");
      } else {
        this.showErrorMsg(this.getMesage(res.data.state));
        //改变子组件的Loading
        this.$refs.delclass.showCommitLoading = false;
      }
    },

    //打开选择课程的弹框
    openChooseCourseDialog(item) {
      if (this.subjectNum == 0) {
        //学校没有开通课程
        this.showWarnMsg("校区暂无权限添加此课程~~");
      } else {
        //学校已经开通课程
        //记录下当前班级对象
        this.currClassObj = item;
        this.showChooseCourseDialog = true;
      }
    },

    //关闭选择课程的弹框
    closeChooseCourseDialog() {
      this.showChooseCourseDialog = false;
    },

    //currentPage 改变时会触发
    handleCurrentChange(currPage) {
      //同步一下当前页码数
      this.currPage = currPage;

      this.sysCurrClassData();
    },

    //刷新页面的方法
    refreshPageData() {
      //重新刷新页面数据
      this.httpData();
    }
  },
  computed: {},
  components: {
    DelStuDialog,
    CreateNewClass,
    ClassMessage,
    UpdateClassNameDialog,
    DelClassDialog,
    ChooseCourse
  }
};
</script>
<style lang="scss" scoped>
.teacher_manager_class {
  background: $common_gray;
  height: calc(100vh - 87px);
  overflow-y: auto;
  .content {
    height: 100%;
    width: $common_width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .top_info {
      margin-top: 30px;
      display: flex;
      align-items: center;
      .class_info {
        flex: 1;
        //班级管理
        .text_class {
          font-size: 14px;
          font-weight: bold;
        }
      }

      //顶部右边的班级个数部分
      .top_right {
        display: flex;
        align-items: center;
        font-size: 12px;
        // 创建新班级
        .create_class {
          margin-left: 10px;
          cursor: pointer;
          color: white;
          font-size: 12px;
          width: 140px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $common_bg;
          border-radius: 20px;
        }
      }
    }

    .show_content {
      flex: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;
      //显示班级列表以及空视图
      .show_class_list,
      .class_empty {
        position: absolute;
        height: 100%;
        width: 100%;
      }

      .show_class_list {
        display: flex;
        flex-direction: column;

        .item_class {
          box-shadow: 0 2px 2px 0 rgba($color: #000000, $alpha: 0.2); //底部阴影
          height: 200px;
          margin-bottom: 20px;
          width: 100%;
          background: white;
          border-radius: 4px;
          font-size: 12px;
          display: flex;

          //条目内容
          .item_class_content {
            flex: 1;
            margin: 10px;
            display: flex;
            flex-direction: column;
            position: relative;

            //课程信息部分
            .course_info {
              margin: 4px 0;
              display: flex;
              align-items: center;
              //课程内容
              .course_content {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .course_details {
                  width: 100%;
                  display: flex;

                  .cousr_item {
                    border: 1px solid $common_bg;
                    border-radius: 2px;
                    padding: 1px 6px;
                    margin-right: 6px;
                    font-size: 10px;
                  }
                }
              }

              //添加课程
              .add_course {
                cursor: pointer;
                color: white;
                // color: $common_bg;
                background: $common_bg;
                width: 80px;
                height: 26px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
              }
            }

            //顶部部分
            .top {
              margin-top: 8px;
              display: flex;
              align-items: center;

              .cloud-bianji {
                font-size: 14px;
                margin: 0 16px;
                cursor: pointer;
                color: $common_bg;
              }

              .cloud-bianji:before {
                vertical-align: sub;
              }

              .class_id {
                margin-left: 12px;
                margin-right: 16px;
              }

              .place_holder {
                flex: 1;
              }

              //复制邀请码
              .copy_code {
                cursor: pointer;
                margin: 0 16px;
                height: 26px;
                width: 60px;
                color: white;
                font-weight: bold;
                display: flex;
                align-items: center;
                border-radius: 20px;
                justify-content: center;
                background: $common_bg;
              }

              //消息
              .cloud-xiaoxizhongxin {
                margin-right: 4px;
                color: $common_bg;
                font-size: 14px;
                cursor: pointer;
              }

              .cloud-xiaoxizhongxin:before {
                vertical-align: sub;
              }
            }

            //中间展示学生列表部分
            .mid {
              flex: 1;
              position: relative;
              .show_student_list,
              .empty_styent {
                position: absolute;
                height: 100%;
                width: 100%;
              }
              .empty_styent {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: $text_gray;
              }

              //展示学生的区域
              .show_student_list {
                display: flex;
                align-items: center;

                .left_btn,
                .right_btn {
                  cursor: pointer;
                  background: #fff;
                  border: none;
                  width: 28px;
                  height: 28px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                //不允许点击时的禁用样式
                .btn_disable {
                  cursor: not-allowed;
                }

                .cloud-zuojiantou,
                .cloud-youjiantou {
                  color: $text_gray;
                  font-size: 14px;
                  cursor: pointer;
                }

                //展示学生的区域
                .show_student_content {
                  flex: 1;
                  display: flex;
                  .item_stuent_info {
                    width: calc(100% / 10);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    //学生的头像部分
                    .head {
                      position: relative;
                      .stu_select,
                      .stu_unselect {
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        top: -2px;
                        left: 2px;
                        position: absolute;
                      }

                      .stu_select {
                        content: url("../../assets/img/selected.png");
                      }

                      .stu_unselect {
                        content: url("../../assets/img/unselect.png");
                      }

                      //学生头像
                      .stu_img {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        object-fit: contain;
                      }
                    }

                    //学生姓名
                    .stu_name {
                      margin-top: 4px;
                      width: 40px;
                      text-align: center;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }
            //底部的操作栏部分
            .bom {
              display: flex;
              align-items: center;
              //管理
              .manager {
                color: $common_bg;
                cursor: pointer;
                margin: 0 20px;
              }
              //全选,删除
              .all_del {
                .all,
                .del {
                  color: $common_bg;
                  cursor: pointer;
                }
                .all {
                  margin-right: 20px;
                }
              }

              //占位符号
              .placholder_view {
                flex: 1;
              }
            }

            //删除班级按钮
            .cloud-lajitong {
              cursor: pointer;
              position: absolute;
              right: 10px;
              bottom: 0;
            }
          }
        }

        //视频列表
        .top_list {
          overflow-y: auto;
          flex: 1;
        }

        //分页
        .bom_page {
          display: flex;
          margin-bottom: 20px;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }

      .class_empty {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text_gray;
        font-size: 16px;
      }

      //去除滚动条
      .show_class_list::-webkit-scrollbar {
        display: none;
      }

      .top_list::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
