//更改班级名称的弹框
<template>
    <div class="update_class_name_dialog">
        <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="update_class_name_dialog_content">
                    <!-- 提示的内容部分 -->
                    <div class="top">
                        <el-input maxlength="20" placeholder="请输入班级名称" v-model="className" clearable />
                    </div>
                    <!-- 底部的取消和确定按钮 -->
                    <div class="bom_action">
                        <!-- 取消 -->
                        <div class="cancel" @click.stop="close">
                            <span>取</span><span style="margin-left: 16px">消</span>
                        </div>
                        <!-- 确定 -->
                        <el-button :loading="showCommitLoading" :class="['commit']" @click.stop="doCommitAction">
                            <span>确</span><span style="margin-left: 16px">定</span>
                        </el-button>
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
export default {
    data() {
        return {
            //宽度
            width: this.dialogWidth,
            //是否显示确定按钮加载框的标识
            showCommitLoading: false,
            //输入的班级名称
            className: "",
        };
    },
    created() {},
    methods: {
        //关闭弹框
        close() {
            this.className = "";
            //触发父组件的关闭方法
            this.$emit("close");
        },

        //确定的操作
        doCommitAction() {
            //先判断用户是否输入了学校名称
            if (
                this.isEmpty(this.className) ||
                this.isAllSpace(this.className)
            ) {
                this.showWarnMsg("请输入修改的班级名称");
                return;
            }

            if (!this.validateRule(this.className)) {
                this.showWarnMsg("只能输入中英文大小写,数字或者下划线");
                return;
            }
            //显示加载框
            this.showCommitLoading = true;

            //调用父组件的修改班级名称的方法
            this.$emit("updateClassName", this.className);
        },
    },
    computed: {},
    components: {
        CommonDialog,
    },
    props: ["showDialog", "title"],
};
</script>
<style lang="scss" scoped>
.update_class_name_dialog {
    .update_class_name_dialog_content {
        display: flex;
        justify-content: center;
        color: black;
        font-size: 14px;
        flex-direction: column;

        //顶部部分
        .top {
            display: flex;
            margin: 20px 0;
            justify-content: center;
            ::v-deep .el-input {
                width: 90%;
                border: none;
            }

            //左右间距
            ::v-deep .el-input__inner {
                padding: 0 10px;
            }

            //获取焦点的颜色
            ::v-deep .el-input__inner:focus {
                border-color: #dcdfe6;
            }

            //移入时的颜色
            ::v-deep .el-input__inner:hover {
                border-color: #dcdfe6;
            }

            // .el-input__inner {
            //   border-color: #c0c4cc;
            // }

            // ::v-deep .el-input.is-active .el-input__inner,
            // .el-input__inner:focus {
            // }
        }

        //底部的操作按钮
        .bom_action {
            margin-top: 20px;
            height: 48px;
            display: flex;
            justify-content: center;

            .cancel,
            .commit {
                width: 120px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                cursor: pointer;
            }

            .cancel {
                background: #dcdcdc;
                margin-right: 30px;
                color: black;
            }

            .commit {
                background: $common_bg;
                color: white;
            }
        }
    }
}
</style>